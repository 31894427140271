import React from 'react';
import Typography from '@gymondo/frontend-core/typography';
import style from './rewardCustomInfo.module.scss';
import classNames from 'classnames';
import {
    GymondoPlusLogo,
    icEqual,
    icPlus,
    LPGanzkoerperRewardShape,
    Offer2,
    PFGanzkoerperReward1,
    PFGanzkoerperReward2,
} from 'assets';

const RewardCustomInfo: React.FC<{ title: string }> = ({ title }) => {
    return (
        <>
            <ul className={style.stepsWrapper}>
                <li className={classNames(style.step, style.active)}>
                    <Typography as="span" variant="body1" className={style.text}>
                        Kurs wählen
                    </Typography>
                </li>
                <li className={classNames(style.bar, style.active)}></li>
                <li className={classNames(style.step, style.active)}>
                    <Typography as="span" variant="body1" className={style.text}>
                        Anmelden
                    </Typography>
                </li>
                <li className={style.bar}></li>
                <li className={style.step}>
                    <Typography as="span" variant="body1" className={style.text}>
                        Loslegen
                    </Typography>
                </li>
            </ul>
            <Typography as="h2" variant="h4">
                Starte jetzt kostenlos mit deinem {title}
            </Typography>
            <div className={classNames('row', style.infoGraphic)}>
                <div className={classNames('col-xs-offset-3 col-xs-6 col-md-offset-1 col-md-4', style.graphicCol)}>
                    <img src={PFGanzkoerperReward1} alt="prävention" />
                </div>
                <div className={classNames('col-xs-12 col-md-1', style.graphicCol)}>
                    <img src={icPlus} alt="plus" className={style.icon} />
                </div>
                <div className={classNames('col-xs-offset-4 col-xs-4 col-md-offset-0 col-md-2', style.graphicCol)}>
                    <img src={PFGanzkoerperReward2} alt="gymondo App" />
                </div>
                <div className={classNames('col-xs-12 col-md-1', style.graphicCol)}>
                    <img src={icEqual} alt="equal" className={style.icon} />
                </div>
                <div className={classNames('col-xs-offset-4 col-xs-4 col-md-offset-0 col-md-3', style.graphicCol)}>
                    <div className={style.priceWrapper}>
                        <img src={LPGanzkoerperRewardShape} alt="preis" />
                        <div className={style.text}>
                            <Typography as="div" variant="h3" color="inverted" className={style.striped}>
                                219€
                            </Typography>
                            <Typography as="div" variant="body1" color="inverted">
                                kostenlos!*
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames('row', style.offerDetails)}>
                <div className={classNames('col-xs-6 col-md-offset-1 col-md-4', style.graphicCol)}>
                    <Typography variant="h5" className={style.secondaryText}>
                        Präventionskurs
                    </Typography>
                    <div className={style.box}>
                        <Typography as="div" variant="h5" color="inverted" className={style.striped}>
                            99€
                        </Typography>
                        <Typography as="div" variant="body1" color="inverted">
                            Krankenkasse zahlt bis zu 100%
                        </Typography>
                    </div>
                </div>
                <div className={classNames('col-xs-6  col-md-4', style.graphicCol)}>
                    <img src={GymondoPlusLogo} alt="Gymondo Plus" className={style.logo} />

                    <div className={classNames(style.box, style.primaryBox)}>
                        <Typography as="div" variant="h5" color="inverted" className={style.striped}>
                            119,99€
                        </Typography>
                        <Typography as="div" variant="body1" color="inverted">
                            12 Monate Gymondo Plus gratis
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography as="h3" variant="h5">
                *So schnell erhältst du deine Erstattung:
            </Typography>
            <Typography as="p" variant="body1">
                Sobald du den 10-Wochen-Kurs abgeschlossen hast, erstattet deine Krankenkasse bis zu 100% der Kosten. Du
                streckst im nächsten Schritt einmalig 99,00 € vor. Du kannst außerdem sofort die Gymondo Plus Plattform
                nutzen.
            </Typography>
        </>
    );
};

export default RewardCustomInfo;
